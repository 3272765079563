import { useMemoizedFn, useSize } from 'ahooks';
import { DrawerProps } from 'antd';
import { Resizable, ResizeCallback } from 're-resizable';
import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import cx from 'classnames';

const Drawer = window.antd.Drawer;

const FlexDrawer = (props: DrawerProps) => {
  //水平方向拖动
  const vertical = props.placement === 'left' || props.placement === 'right' || !props.placement;
  const minWidth = 200;
  const minHeight = 100;
  const [setterWidth, setSetterWidth] = useState<number | string>();
  const [maxWidth, setMaxWidth] = useState(200);
  const [setterHeight, setSetterHeight] = useState<number | string>();
  const [maxHeight, setMaxHeight] = useState(200);

  const size = useSize(document.querySelector('body'));

  useEffect(() => {
    if (!props) return;
    const { clientWidth, clientHeight } = window.document.documentElement;
    // 水平控宽度
    if (vertical) {
      setMaxWidth(clientWidth);
      setSetterWidth(props.width || 200);
    } else {
      setSetterHeight(props.height || 100);
      setMaxHeight(clientHeight);
    }
  }, [props]);

  useEffect(() => {
    if (!size) return;
    if (vertical) {
      setMaxWidth(size.width);
      if (size?.width <= Number(setterWidth)) setSetterWidth(size.width);
    } else {
      setMaxHeight(size.height);
      if (size?.height <= Number(setterHeight)) setSetterHeight(size.height);
    }
  }, [size]);

  const onResize: ResizeCallback = useMemoizedFn(
    (e: MouseEvent | TouchEvent, direction, element) => {
      if (vertical) {
        const offsetWidth = element.offsetWidth;
        setSetterWidth(offsetWidth);
      } else {
        const offsetHeight = element.offsetHeight;
        setSetterHeight(offsetHeight);
      }
    },
  );

  return (
    <Drawer {...props} width={setterWidth} height={setterHeight}>
      <Resizable
        minWidth={vertical ? minWidth : undefined}
        maxWidth={vertical ? maxWidth : undefined}
        minHeight={!vertical ? minHeight : undefined}
        maxHeight={!vertical ? maxHeight : undefined}
        onResize={onResize}
        style={{ width: '100%', height: '100%' }}
        enable={{
          right: props.placement == 'left',
          left: props.placement == 'right' || !props.placement,
          top: props.placement == 'bottom',
          bottom: props.placement == 'top',
        }}
        className={cx(vertical ? styles['vertical-container'] : styles['hori-container'])}
      >
        <div
          className={cx(
            'padding-container',
            styles['padding-control'],
            styles['qiankun-spin-container'],
          )}
        >
          {props.children}
        </div>
      </Resizable>
    </Drawer>
  );
};
export default FlexDrawer;
