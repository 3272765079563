const localStorageDebugKey = 'eimos:debug:log';

/**
 * 自定义 log
 */
export const log = {
  /**
   * log info
   */
  info(name: string, ...args: any[]) {
    try {
      console.info(`%cAPPSTORE:${name}`, 'color: #0064ce; font-weight: bold;', ...args);
    } catch (e) {
      console.error('log 出错', e);
    }
  },
  warn(name: string, ...args: any[]) {
    try {
      console.warn(`APPSTORE:${name}`, ...args);
    } catch (e) {
      console.error('log 出错', e);
    }
  },
  error(name: string, ...args: any[]) {
    try {
      console.error(`APPSTORE:${name}`, ...args);
    } catch (e) {
      console.error('log 出错', e);
    }
  },
  /**
   * 需先开启 debug 模式才会打印，window.eimos.debugLog(true) 开启打印模式
   */
  debugInfo(name: string, ...args: any[]) {
    if (localStorage.getItem(localStorageDebugKey) == 'true') {
      this.info(name, ...args);
    }
  },
  /**
   * 需先开启 debug 模式才会打印，window.eimos.debugLog(true) 开启打印模式
   */
  debugWarn(name: string, ...args: any[]) {
    if (localStorage.getItem(localStorageDebugKey) == 'true') {
      this.warn(name, ...args);
    }
  },
};
