export enum UI_TYPES {
  MODAL = 'modal',
  DRAWER = 'drawer',
  BOTTOMSUCTIONDRAWER = 'bottomSuctionDrawer',
  RIGHTTELESCOPICDRAWER = 'rightTelescopicDrawer',
}

type Noop = (...args: any[]) => any;

export interface BuiQiankunParamsType {
  route: string;
  type: UI_TYPES;
  props?: Record<string, any>;
  onHide?: Noop;
  onOk?: (value: any) => any;
  saveSuccess?: Noop;
  /**
   * 微应用通用通信方式
   */
  notify?: (...args: any[]) => void;
  containerDom?: HTMLElement;
  // 自定义弹窗关闭回调
  customOnClose?: Noop;
  qiankunId: string;
  defaultWidth?: string;
  defaultHeight?: string;
  title?: string;
  drawerDirection?: 'top' | 'right' | 'bottom' | 'left'; // 打开抽屉的方向
}
export interface BuiQiankunType {
  (o: BuiQiankunParamsType): JSX.Element;
}
