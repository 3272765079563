import { appName } from '@/config';
import _ from 'lodash';
import { log } from './log';
/**
 * 解析 url search 参数，支持json对象嵌套
 * @param params
 * @returns
 */
export const parseParams = (params: Record<string, any>) => {
  const parsedParams = Object.keys(params).reduce((pre, key) => {
    try {
      const parsedValue = JSON.parse(params?.[key]);
      if (_.isPlainObject(parsedValue)) {
        pre[key] = parsedValue;
      } else {
        pre[key] = params[key];
      }
    } catch (e) {
      pre[key] = params[key];
    }
    return pre;
  }, Object.create({}) as Record<string, any>);
  return parsedParams;
};

export const logBuildPageUrl = (pageRoute?: string) => {
  const buildPage = getBuildPageUrl(pageRoute);
  if (buildPage) {
    log.infoInternal('配置当前页面', buildPage);
  }
};

export const getBuildPageUrl = (pageRoute?: string, appCode?: string) => {
  if (!pageRoute) {
    return;
  }
  try {
    const searchParams = {
      route: pageRoute,
    };
    const urlSearchParams = new URLSearchParams(searchParams);
    if (appCode) {
      urlSearchParams.set('appCode', appCode);
    }
    const search = `?${String(urlSearchParams)}`;
    const buildPage = `${location.protocol}//${location.host}/${appName}/pageDesign${search}`;
    return buildPage;
  } catch (e) {
    console.error('生成编辑页地址报错', e);
  }
  return '';
};

export const get360PageUrl = (sceneCode?: string) => {
  return `${location.protocol}//${location.host}/${appName}/x360_config?sceneCode=${sceneCode}`;
};

/**
 * 拼装 query string
 */
export const buildQueryString = (route: string, data?: Record<string, any>): string => {
  if (!data) {
    return route;
  }
  const arr = Object.keys(data).map((key: string) => {
    const value =
      typeof data?.[key] === 'object' && data?.[key] ? JSON.stringify(data?.[key]) : data?.[key];
    return `${key}=${value}`;
  });
  const res = `${route}?${arr.join('&')}`;
  return res;
};

export function queryStringToObject<T extends Record<string, any>>(
  queryString: string | URLSearchParams,
): T {
  const searchParams =
    typeof queryString === 'string' ? new URLSearchParams(queryString) : queryString;

  const result: any = {};

  for (const [key, value] of searchParams) {
    // 如果键已存在，将值转换为数组
    if (key in result) {
      if (Array.isArray(result[key])) {
        result[key].push(value);
      } else {
        result[key] = [result[key], value];
      }
    } else {
      result[key] = value;
    }
  }

  return result as T;
}

/**
 *
 * @param url1 将 url2 的 search 和冰岛 url1 上，url1 的权重更高
 * @param url2
 * @returns string
 */
export const mergeUrlParams = (url1: string, url2: string): string => {
  const globalParams = new URLSearchParams(url1);
  const [, urlSearch] = url2.split('?') || [];
  const urlParams = new URLSearchParams(urlSearch || '');
  if (globalParams.size && urlParams.size) {
    // url params 优先级高于 global
    urlParams.forEach((value, key) => {
      globalParams.set(key, value);
    });
  }

  const newUrl = globalParams.size ? `${url1}?${globalParams}` : url1;
  return newUrl;
};
