import { Apis } from '@bui/req';

type LcpBaseParams = {
  pageType: 'create' | 'edit' | 'detail' | 'diff';
  recordID?: string;
};

/**
 * 从路由解析出的 lcp task 参数
 */
interface LcpTaskParams extends LcpBaseParams {
  taskId: string;
}

/**
 * 从路由解析出的 lcp 视图模型参数
 */
interface LcpModelParams extends LcpBaseParams {
  modelGroupId: string;
}

/**
 * 获取原始的 lcp 路由参数
 * @param params
 * @returns
 */
export const getRawLcpParams = (params: {
  route: string;
}): LcpTaskParams | LcpModelParams | null => {
  const { route } = params;
  const isTaskRoute = /^(\/pro)?(\/appStore)?(\/lcp)?\/task\//.test(route);
  const isViewModelRoute = /^(\/pro)?(\/appStore)?(\/lcp)?\/vm\//.test(route);
  // if (!isLcpRoute && !isViewModel) return null;
  if (isTaskRoute) {
    const matchRouteExp =
      /^(\/pro)?(\/appStore)?(\/lcp)?\/task\/(?<taskId>[\w-]+)\/(?<pageType>[\w-]+)(\/(?<recordID>[\w-]+))?$/;
    const matchConfig = matchRouteExp.exec(route);
    const groups = matchConfig?.groups;
    return groups as any as LcpTaskParams;
  }

  if (isViewModelRoute) {
    const matchViewModelRouteExp =
      /^(\/pro)?(\/appStore)?(\/lcp)?\/vm\/(?<modelGroupId>[\w-]+)\/(?<pageType>[\w-]+)(\/(?<recordID>[\w-]+))?$/;
    const matchConfig = matchViewModelRouteExp.exec(route);
    const groups = matchConfig?.groups;
    return groups as any as LcpModelParams;
  }

  return null;
};

// 获取低代码路由的接口入参
export const getLcpParams = (params: { route: string }) => {
  const { route } = params;
  const groups = getRawLcpParams({ route });
  if (!groups) {
    return false;
  }
  const { pageType } = groups;
  const page_preset_type = pageType === 'edit' ? 'create' : pageType;
  if ((groups as LcpTaskParams).taskId) {
    return {
      page_preset_type,
      task_id: (groups as LcpTaskParams).taskId,
      record_id: groups?.recordID,
    };
  }
  if ((groups as LcpModelParams).modelGroupId) {
    return {
      page_preset_type,
      model_group_id: (groups as LcpModelParams).modelGroupId,
      record_id: groups?.recordID,
    };
  }
  return {
    page_preset_type: page_preset_type as 'create' | 'detail' | 'diff' | 'list',
    model_id: (groups as any).modelId,
    record_id: groups?.recordID,
  };
};

/**
 * 根据路由获取路由参数，此参数用于请求 page 接口
 * 对应于 page_route 参数
 * /pro/appStore/xxx => /xxx
 * /pro/xxx => /xxx
 * /xxx => /xxx
 * /appStore/xxx => /xxx
 */
export const getRouteParam = (pathname: string) => {
  const regex = /^\/?(?:pro\/)?(?:appStore\/)?(.+)/;
  const [, p] = regex.exec(pathname) || [];
  const route = p ? `/${p}` : pathname;
  return route;
};

export const getPageVersion = async (params: { route: string }) => {
  const route = getRouteParam(params.route);
  const lcpParams = getLcpParams({ route });
  const ret = await Apis.request(
    {
      url: '/api/gateway/metabase/compose/getPageVersion',
      method: 'post',
      cache: true,
      data: {
        taskId: lcpParams && lcpParams.task_id,
        pagePresetType: lcpParams && lcpParams.page_preset_type,
        pageRoute: params.route,
      },
    },
    { error: false },
  );

  const page = ret?.page?.data?.[0];
  const pageVersion = ret?.pageVersion?.data?.[0];
  return {
    page,
    pageVersion,
  };
};
