import { prefetch } from './prefetch';

import { getPageVersion } from './appStore';
import { getLcpSchema } from './lcp';
prefetch.batch([
  () => {
    const pathname = window.location.pathname;
    if (pathname.includes('/lcp')) {
      getPageVersion({ route: pathname.replace(/^\/pro/, '') });
    }
  },
  () => {
    const pathname = window.location.pathname;
    if (pathname.includes('/lcp/task')) {
      getLcpSchema({ route: pathname.replace(/^\/pro/, '') });
    }
  },
  // () => {
  //   const pathname = window.location.pathname;
  //   if (pathname.includes('/lcp/task')) {
  //     getLcpData({ route: pathname.replace(/^\/pro/, '') });
  //   }
  // },
]);

export { prefetch };
