import type { ChatMode } from '@bui/ai-assistant/dist/const';

export type QiankunGlobalState = {
  INTERACTION?: boolean;
  INTERACTION_CONFIG?: unknown;
  OPEN_TAB?: boolean;
  OPEN_TAB_CONFIG?: {
    title: string;
    path: string;
  };
};

export type Qiankun = {
  IS_TAB_MICRO_APP?: boolean;
  changeTabTitle?(title: string): void;
  closeMicroAppTab?(): void;
  container?: HTMLElement;
  setGlobalState?: (state: QiankunGlobalState) => void;
  fetchInteraction?: unknown;
  openTab?: (params: { title: string; url: string }) => void;
  openAI?: (cfg: any, type: ChatMode) => void;
  closeModal?: (data: any) => void;
};

let qiankun: Qiankun;
const useQiankun = () => {
  const setQiankun = (newQiankun: Qiankun) => {
    qiankun = newQiankun;
    // buiAppStore.setQiankun(newQiankun);
  };
  return {
    qiankun,
    setQiankun,
  };
};

export default useQiankun;
