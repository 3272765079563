import { useMemo, useState } from 'react';
import { IpropsDrawer } from '../TelescopicDrawer';

const ENABLE = {
  top: false,
  right: false,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

type UsePositionProps = Partial<IpropsDrawer>;

export const usePosition = (parms: UsePositionProps) => {
  const { width: defaultWdith, height: defaultHeight, position, getContainer, mask } = parms;
  const baseLength = position == 'right' ? defaultWdith : defaultHeight;
  const [drawerlen, setDrawerlen] = useState(baseLength || 300);

  const resizebleConfig = useMemo(() => {
    const notMaskStyles = {
      backgroundColor: '#fff',
      position: getContainer === false ? 'absolute' : 'fixed',
    };

    if (position == 'bottom') {
      return {
        flexKey: 'height',
        style: {
          bottom: 0,
          ...(!mask ? notMaskStyles : {}),
        },
        size: {
          width: '100%',
          height: drawerlen,
        },
        enable: {
          ...ENABLE,
          top: true,
        },
        props: {
          minHeight: defaultHeight || 150,
        },
      };
    }

    return {
      flexKey: 'width',
      style: {
        top: 0,
        ...(!mask ? notMaskStyles : {}),
      },
      size: {
        width: drawerlen,
        height: '100%',
      },
      enable: {
        ...ENABLE,
        left: true,
      },
      props: {
        minWidth: defaultWdith || 300,
      },
    };
  }, [position, drawerlen, defaultWdith, defaultHeight]);

  return {
    drawerlen,
    setDrawerlen,
    resizebleConfig,
  };
};
