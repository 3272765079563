/*
 * @Author: mayefeng
 * @Date: 2022-11-25 14:41:01
 * @LastEditors: mayefeng
 * @LastEditTime: 2022-11-25 14:41:29
 * @FilePath: /lcp-viewer/src/utils/events.ts
 * @Description: update here
 */
import { EventEmitter } from 'events';
export const eventBus = new EventEmitter();
