import Cookies from 'js-cookie';

export const getHomeLink = () => {
  let redirectPath = '';
  try {
    const initHome = Cookies.get('homePageConfig');
    if (initHome) {
      const initHomeConfig = JSON.parse(initHome);
      redirectPath = initHomeConfig?.url || redirectPath;
    }
    const TOKEN = Cookies.get('access_token');
    if (!redirectPath && !TOKEN) {
      redirectPath = '/';
    } else if (!redirectPath) {
      redirectPath = '/pro/portal/home';
    }
  } catch (err) {
    console.log('err', err);
  }
  if (!redirectPath.startsWith('/')) {
    console.error('homelink 配置错误，请以 / 开头', redirectPath);
    redirectPath = `/${redirectPath}`;
  }
  return redirectPath;
};
