import { Apis } from '@bui/req';
import { getLcpParams, getRouteParam } from './appStore';

/**
 * 获取 lcp schema
 * @param params
 * @returns
 */
export const getLcpSchema = async (params: { route: string }) => {
  const route = getRouteParam(params.route);
  const lcpParams = getLcpParams({ route });
  if (!lcpParams) {
    return;
  }
  const pageType = 'task';
  const pageId = lcpParams.task_id;

  // const { pageType, pageId, taskId, pageModelId, recordId } = params;
  return Apis.requestRaw({
    url: `/api/gateway/metabase/compose/page/schema/${pageType}/${pageId}`,
    method: 'get',
    cache: true,
  });
};

export const getLcpData = async (params: { route: string }) => {
  const route = getRouteParam(params.route);
  const lcpParams = getLcpParams({ route });
  if (!lcpParams || lcpParams.page_preset_type === 'list') {
    return;
  }
  const pageId = lcpParams.task_id;
  const taskId = pageId;
  const recordId = lcpParams.record_id;
  // const { pageType, pageId, taskId, pageModelId, recordId } = params;
  return Apis.requestRaw({
    url: `/api/gateway/process_platform/data/query/latest/${taskId}/${recordId}`,
    method: 'post',
    data: {},
    cache: true,
    max: 1,
    // once: true,
  });
};
