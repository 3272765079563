const microAppNames = new Map([
  ['portal', 'EIMOS-PORTAL'],
  ['task', 'EIMOS-TASK'],
  ['bi', 'EIMOS-BI'],
  ['cutler', 'EIMOS-CUTLER'],
  ['usercenter', 'EIMOS-USERCENTER'],
  ['integrate', 'EIMOS-INTEGRATE'],
  ['data-qualtity', 'EIMOS-DATA-QUALTITY'],
  ['indicator-parser', 'EIMOS-INDICATOR-PARSER'],
  ['indicator', 'EIMOS-INDICATOR'],
  ['timeline', 'EIMOS-TIMELINE'],
  ['baseline', 'EIMOS-BASELINE'],
  ['integration', 'EIMOS-INTEGRATION'],
  ['ipscustom', 'EIMOS-IPSCUSTOM'],
  ['icmcustom', 'EIMOS-ICMCUSTOM'],
  ['ismp', 'EIMOS-ISMP'],
  ['mdmcustom', 'EIMOS-MDMCUSTOM'],
  ['mission-orde,', 'EIMOS-MISSION-ORDER'],
  ['jurisdiction', 'EIMOS-JURISDICTION'],
  ['platform-maker', 'EIMOS-PLATFORM-MAKER'],
  ['data-governance', 'EIMOS-DATA-GOVERNANCE'],
  ['data-exploration', 'EIMOS-DATA-EXPLORATION'],
  ['orchestration', 'EIMOS-ORCHESTRATION'],
  ['appStore', 'EIMOS-APPSTORE'],
]);

export const getMicroAppEntry = (appName: string) => {
  const appSuperName = microAppNames.get(appName);
  const localEntry = localStorage.getItem(`eimos:entry:${appSuperName}`);
  if (localEntry) {
    return localEntry;
  }
  return `${window.location.origin}/${appName}/index.html`;
};
