import { CaptureOptions, Monitor, MonitorConfig } from '@/infra/monitor';
import Store, { BaseStore } from 'zustand-store';

export class UseMonitorStore extends BaseStore<UseMonitorStore> {
  static monitor: Monitor;
  async init(config: MonitorConfig) {
    UseMonitorStore.monitor = new Monitor({
      enable: config.enabled,
      type: config.type,
    });
    await UseMonitorStore.monitor.init(config);
  }
  start() {
    UseMonitorStore.monitor.start();
  }
  info(eventName: string, options: Partial<Omit<CaptureOptions, 'level'>>) {
    UseMonitorStore.monitor.info(eventName, options);
  }
}

export const useMonitorStore = Store.create(UseMonitorStore);
