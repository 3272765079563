interface LogControl {
  logEnabled: boolean;
  infoEnabled: boolean;
  debugEnabled: boolean;
  warnEnabled: boolean;
  errorEnabled: boolean;
  globalLogEnabled: boolean;
  globalInfoEnabled: boolean;
  globalDebugEnabled: boolean;
  globalWarnEnabled: boolean;
  globalErrorEnabled: boolean;
  internalInfoEnabled: boolean;
  internalDebugEnabled: boolean;
  internalWarnEnabled: boolean;
  internalErrorEnabled: boolean;
}

export class Log {
  public namespace: string;
  logEnabled: boolean;
  infoEnabled: boolean;
  debugEnabled: boolean;
  warnEnabled: boolean;
  errorEnabled: boolean;

  globalLogEnabled: boolean;
  globalDebugEnabled: boolean;
  globalInfoEnabled: boolean;
  globalWarnEnabled: boolean;
  globalErrorEnabled: boolean;

  internalInfoEnabled: boolean;
  internalDebugEnabled: boolean;
  internalWarnEnabled: boolean;
  internalErrorEnabled: boolean;
  originLog = window.console.log.bind(console);
  originInfo = window.console.info.bind(console);
  originDebug = window.console.debug.bind(console);
  originWarn = window.console.warn.bind(console);
  originError = window.console.error.bind(console);

  key: string;

  constructor(namespace: string) {
    this.namespace = namespace;
    this.key = `eimos:log:${this.namespace}`;
    const logControl = this.getLogControlFromLocalStorage();
    if (logControl) {
      this.logEnabled = logControl.logEnabled == undefined ? true : logControl.logEnabled;
      this.infoEnabled = logControl.infoEnabled == undefined ? true : logControl.infoEnabled;
      this.debugEnabled = logControl.debugEnabled == undefined ? true : logControl.debugEnabled;
      this.warnEnabled = logControl.warnEnabled == undefined ? true : logControl.warnEnabled;
      this.errorEnabled = logControl.errorEnabled == undefined ? true : logControl.errorEnabled;

      this.globalLogEnabled =
        logControl.globalLogEnabled == undefined ? true : logControl.globalLogEnabled;
      this.globalDebugEnabled =
        logControl.globalDebugEnabled == undefined ? true : logControl.globalDebugEnabled;
      this.globalInfoEnabled =
        logControl.globalInfoEnabled == undefined ? true : logControl.globalInfoEnabled;
      this.globalWarnEnabled =
        logControl.globalWarnEnabled == undefined ? true : logControl.globalWarnEnabled;
      this.globalErrorEnabled =
        logControl.globalErrorEnabled == undefined ? true : logControl.globalErrorEnabled;

      this.internalInfoEnabled = logControl.internalInfoEnabled;
      this.internalDebugEnabled = logControl.internalDebugEnabled;
      this.internalWarnEnabled = logControl.internalWarnEnabled;
      this.internalErrorEnabled = logControl.internalErrorEnabled;
    } else {
      this.logEnabled = true;
      this.infoEnabled = true;
      this.debugEnabled = true;
      this.warnEnabled = true;
      this.errorEnabled = true;

      this.globalLogEnabled = true;
      this.globalDebugEnabled = true;
      this.globalInfoEnabled = true;
      this.globalWarnEnabled = true;
      this.globalErrorEnabled = true;

      this.internalInfoEnabled = false;
      this.internalDebugEnabled = false;
      this.internalWarnEnabled = false;
      this.internalErrorEnabled = false;
      this.saveLogControlToLocalStorage();
    }

    const disabledTypes = [
      {
        type: 'log',
        value: this.globalLogEnabled,
      },
      {
        type: 'info',
        value: this.globalInfoEnabled,
      },
      {
        type: 'warn',
        value: this.globalWarnEnabled,
      },
      {
        type: 'debug',
        value: this.globalDebugEnabled,
      },
      {
        type: 'error',
        value: this.globalErrorEnabled,
      },
    ];
    this.hideConsole(
      disabledTypes
        .filter((item) => {
          return !item.value;
        })
        .map((item) => item.type as any),
    );
  }

  private getLogControlFromLocalStorage(): LogControl | null {
    const logControl = localStorage.getItem(this.key);
    return logControl ? JSON.parse(logControl) : null;
  }

  private saveLogControlToLocalStorage(): void {
    const logControl: LogControl = {
      logEnabled: this.logEnabled,
      infoEnabled: this.infoEnabled,
      debugEnabled: this.debugEnabled,
      warnEnabled: this.warnEnabled,
      errorEnabled: this.errorEnabled,
      globalLogEnabled: this.globalLogEnabled,
      globalInfoEnabled: this.globalInfoEnabled,
      globalDebugEnabled: this.globalDebugEnabled,
      globalWarnEnabled: this.globalWarnEnabled,
      globalErrorEnabled: this.globalErrorEnabled,
      internalInfoEnabled: this.internalInfoEnabled,
      internalDebugEnabled: this.internalDebugEnabled,
      internalWarnEnabled: this.internalWarnEnabled,
      internalErrorEnabled: this.internalErrorEnabled,
    };
    localStorage.setItem(this.key, JSON.stringify(logControl));
  }

  hideConsole(types?: Array<'log' | 'info' | 'warn' | 'debug' | 'error'>) {
    types = types || ['log', 'info', 'warn', 'debug', 'error'];
    types.forEach((type) => {
      this.originWarn(
        'log',
        `eimos console.${type} 已全部隐藏，如果要恢复请调用 eimos.log.enableConsole() or eimos.log.enableGlobal${
          type[0].toUpperCase() + type.slice(1)
        }()`,
      );
      window.console[type] = () => {
        //
      };
    });
  }

  public static createLogger(namespace: string): Log {
    if (!namespace) {
      throw new Error('请设置 logger 的命名空间');
    }
    return new Log(namespace);
  }

  public debug(name: string, ...args: any[]): void {
    if (this.debugEnabled) {
      console.debug(`${this.namespace}[${name}:DEBUG]`, ...args);
    }
  }

  public info(name: string, ...args: any[]): void {
    if (this.infoEnabled) {
      console.info(
        `%c${this.namespace}[${name}:INFO]`,
        'color: #0064ce; font-weight: bold;',
        ...args,
      );
    }
  }

  public warn(name: string, ...args: any[]): void {
    if (this.warnEnabled) {
      window.console.warn(`${this.namespace}[${name}:WARN]`, ...args);
    }
  }

  // 示例方法，接收任意参数
  public error(name: string, ...args: any[]): void {
    if (this.errorEnabled) {
      console.error(`${this.namespace}[${name}:ERROR]`, ...args);
    }
  }

  // 示例方法，接收任意参数
  public log(name: string, ...args: any[]): void {
    if (this.logEnabled) {
      console.log(`${this.namespace}[${name}: INTERNAL]`, ...args);
    }
  }

  // 新增的方法，接收任意参数
  public warnInternal(name: string, ...args: any[]): void {
    if (this.internalWarnEnabled) {
      console.warn(`${this.namespace}[${name}: INTERNAL-WARN]`, ...args);
    }
  }

  // 新增的方法，接收任意参数
  public debugInternal(name: string, ...args: any[]): void {
    if (this.internalDebugEnabled) {
      console.debug(`${this.namespace}[${name}: INTERNAL-DEBUG]`, ...args);
    }
  }

  // 新增的方法，接收任意参数
  public infoInternal(name: string, ...args: any[]): void {
    if (this.internalInfoEnabled) {
      console.info(
        `%c${this.namespace}[${name}:INTERNAL-INFO]`,
        'color: #0064ce; font-weight: bold;',
        ...args,
      );
    }
  }

  // 新增的方法，接收任意参数
  public errorInternal(name: string, ...args: any[]): void {
    if (this.internalErrorEnabled) {
      console.error(`${this.namespace}[${name}: INTERNAL-ERROR]`, ...args);
    }
  }

  disableAllLogs() {
    this.infoEnabled = false;
    this.debugEnabled = false;
    this.warnEnabled = false;
    this.errorEnabled = false;
    this.internalInfoEnabled = false;
    this.internalDebugEnabled = false;
    this.internalWarnEnabled = false;
    this.internalErrorEnabled = false;
    this.saveLogControlToLocalStorage();
  }

  enableAllLogs() {
    this.logEnabled = true;
    this.debugEnabled = true;
    this.infoEnabled = true;
    this.warnEnabled = true;
    this.errorEnabled = true;
    this.globalLogEnabled = true;
    this.globalDebugEnabled = true;
    this.globalInfoEnabled = true;
    this.globalWarnEnabled = true;
    this.globalErrorEnabled = true;
    this.internalInfoEnabled = true;
    this.internalDebugEnabled = true;
    this.internalWarnEnabled = true;
    this.internalErrorEnabled = true;
    this.saveLogControlToLocalStorage();
  }
  disableConsole() {
    this.globalLogEnabled = false;
    this.globalDebugEnabled = false;
    this.globalInfoEnabled = false;
    this.globalWarnEnabled = false;
    this.globalErrorEnabled = false;
    this.saveLogControlToLocalStorage();
  }

  enableConsole() {
    this.globalLogEnabled = true;
    this.globalDebugEnabled = true;
    this.globalInfoEnabled = true;
    this.globalWarnEnabled = true;
    this.globalErrorEnabled = true;
    this.saveLogControlToLocalStorage();
  }

  disableNormal() {
    this.logEnabled = false;
    this.debugEnabled = false;
    this.infoEnabled = false;
    this.warnEnabled = false;
    this.errorEnabled = false;
    this.saveLogControlToLocalStorage();
  }
  enableNormal() {
    this.logEnabled = true;
    this.debugEnabled = true;
    this.infoEnabled = true;
    this.warnEnabled = true;
    this.errorEnabled = true;
    this.saveLogControlToLocalStorage();
  }

  enableLog() {
    this.logEnabled = true;
    this.saveLogControlToLocalStorage();
  }
  disableLog() {
    this.logEnabled = false;
    this.saveLogControlToLocalStorage();
  }

  enableInfo() {
    this.infoEnabled = true;
    this.saveLogControlToLocalStorage();
  }
  disableInfo() {
    this.infoEnabled = false;
    this.saveLogControlToLocalStorage();
  }

  enableWarn() {
    this.warnEnabled = true;
    this.saveLogControlToLocalStorage();
  }
  disableWarn() {
    this.warnEnabled = false;
    this.saveLogControlToLocalStorage();
  }

  enableDebug() {
    this.debugEnabled = true;
    this.saveLogControlToLocalStorage();
  }
  disableDebug() {
    this.debugEnabled = false;
    this.saveLogControlToLocalStorage();
  }

  enableError() {
    this.errorEnabled = true;
    this.saveLogControlToLocalStorage();
  }
  disableError() {
    this.errorEnabled = false;
    this.saveLogControlToLocalStorage();
  }

  enableGlobalLog() {
    this.globalLogEnabled = true;
    this.saveLogControlToLocalStorage();
  }
  disableGlobalLog() {
    this.globalLogEnabled = false;
    this.saveLogControlToLocalStorage();
  }

  enableGlobalInfo() {
    this.globalInfoEnabled = true;
    this.saveLogControlToLocalStorage();
  }
  disableGlobalInfo() {
    this.globalInfoEnabled = false;
    this.saveLogControlToLocalStorage();
  }

  enableGlobalWarn() {
    this.globalWarnEnabled = true;
    this.saveLogControlToLocalStorage();
  }
  disableGlobalWarn() {
    this.globalWarnEnabled = false;
    this.saveLogControlToLocalStorage();
  }

  enableGlobalDebug() {
    this.globalDebugEnabled = true;
    this.saveLogControlToLocalStorage();
  }
  disableGlobalDebug() {
    this.globalDebugEnabled = false;
    this.saveLogControlToLocalStorage();
  }

  enableGlobalError() {
    this.globalErrorEnabled = true;
    this.saveLogControlToLocalStorage();
  }
  disableGlobalError() {
    this.globalErrorEnabled = false;
    this.saveLogControlToLocalStorage();
  }

  disableInternal() {
    this.internalDebugEnabled = false;
    this.internalErrorEnabled = false;
    this.internalInfoEnabled = false;
    this.internalWarnEnabled = false;
    this.saveLogControlToLocalStorage();
  }

  enableInternal() {
    this.internalDebugEnabled = true;
    this.internalErrorEnabled = true;
    this.internalInfoEnabled = true;
    this.internalWarnEnabled = true;
    this.saveLogControlToLocalStorage();
  }

  disableInternalInfo() {
    this.internalInfoEnabled = false;
    this.saveLogControlToLocalStorage();
  }

  enableInternalInfo() {
    this.internalInfoEnabled = true;
    this.saveLogControlToLocalStorage();
  }

  disableInternalDebug() {
    this.internalDebugEnabled = false;
    this.saveLogControlToLocalStorage();
  }

  enableInternalDebug() {
    this.internalDebugEnabled = true;
    this.saveLogControlToLocalStorage();
  }

  disableInternalWarn() {
    this.internalWarnEnabled = false;
    this.saveLogControlToLocalStorage();
  }

  enableInternalWarn() {
    this.internalWarnEnabled = true;
    this.saveLogControlToLocalStorage();
  }

  disableInternalError() {
    this.internalErrorEnabled = false;
    this.saveLogControlToLocalStorage();
  }

  enableInternalError() {
    this.internalErrorEnabled = true;
    this.saveLogControlToLocalStorage();
  }
}
export const log = Log.createLogger('APPSTORE');

window.eimos = window.eimos || ({} as any);
