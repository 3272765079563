(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("icons"));
	else if(typeof define === 'function' && define.amd)
		define(["icons"], factory);
	else if(typeof exports === 'object')
		exports["appManagement"] = factory(require("icons"));
	else
		root["appManagement"] = factory(root["icons"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__15041__) {
return 