import React from 'react';
import styles from './index.module.less';
import { Empty } from 'antd';

const NotFound: React.FC = () => {
  return (
    <div className={styles['not-found']}>
      <Empty description="404"></Empty>
    </div>
  );
};

export default NotFound;
