import { log } from '@/infra';
import { RegistrableApp } from 'qiankun';

type App = {
  name: string;
  entry: string;
  activeRule: string;
};
const getEntry = (app: App) => {
  const entry = localStorage.getItem(`eimos:entry:${app.name}`);
  if (entry) {
    app.entry = entry;
  }
  return app;
};

/**
 * @description 支持的微应用列表
 */
let { origin } = location;
if (location.host.startsWith('local') && process.env.NODE_ENV === 'development') {
  origin = `${location.protocol}//${location.hostname.replace('local.', '')}`;
}

export const microAppList: Array<Omit<RegistrableApp<any>, 'container'>> = [
  {
    name: 'EIMOS-PORTAL',
    entry: `${origin}/portal/index.html`,
    activeRule: '/pro/portal',
  },
  {
    name: 'EIMOS-TASK',
    entry: `${origin}/task/index.html`,
    activeRule: '/pro/task',
  },
  {
    name: 'EIMOS-BI',
    entry: `${origin}/bi/index.html`,
    activeRule: '/pro/bi',
  },
  {
    name: 'EIMOS-CUTLER',
    entry: `${origin}/cutler/index.html`,
    activeRule: '/pro/cutler/compose',
  },
  {
    name: 'EIMOS-USERCENTER',
    entry: `${origin}/usercenter/index.html`,
    activeRule: '/pro/usercenter',
  },
  // {
  //   name: 'EIMOS-LCP',
  //   entry: `${origin}/lcp/index.html`,
  //   activeRule: '/pro/lcp',
  // },
  {
    name: 'EIMOS-INTEGRATE',
    entry: `${origin}/integrate/index.html`,
    activeRule: '/pro/integrate',
  },
  {
    name: 'EIMOS-DATA-QUALTITY',
    entry: `${origin}/data-qualtity/index.html`,
    activeRule: '/pro/data-qualtity',
  },
  {
    name: 'EIMOS-INDICATOR-PARSER',
    entry: `${origin}/indicator-parser/index.html`,
    activeRule: '/pro/indicator-parser',
  },
  {
    name: 'EIMOS-INDICATOR',
    entry: `${origin}/indicator/index.html`,
    activeRule: '/pro/indicator',
  },
  {
    name: 'EIMOS-TIMELINE',
    entry: `${origin}/timeline/index.html`,
    activeRule: '/pro/timeline',
  },
  {
    name: 'EIMOS-BASELINE',
    entry: `${origin}/baseline/index.html`,
    activeRule: '/pro/baseline',
  },
  {
    name: 'EIMOS-INTEGRATION',
    entry: `${origin}/integration/index.html`,
    activeRule: '/pro/integration',
  },
  {
    name: 'EIMOS-IPSCUSTOM',
    entry: `${origin}/ipscustom/index.html`,
    activeRule: '/pro/ipscustom',
  },
  {
    name: 'EIMOS-ICMCUSTOM',
    entry: `${origin}/icmcustom/index.html`,
    activeRule: '/pro/icmcustom',
  },
  {
    name: 'EIMOS-ISMP',
    entry: `${origin}/ismp/index.html`,
    activeRule: '/pro/ismp',
  },
  {
    name: 'EIMOS-MDMCUSTOM',
    entry: `${origin}/mdmcustom/index.html`,
    activeRule: '/pro/mdmcustom',
  },
  {
    name: 'EIMOS-MISSION-ORDER',
    entry: `${origin}/mission-order/index.html`,
    activeRule: '/pro/mission-order',
  },
  {
    name: 'EIMOS-JURISDICTION',
    entry: `${origin}/jurisdiction/index.html`,
    activeRule: '/pro/jurisdiction',
  },
  {
    name: 'EIMOS-PLATFORM-MAKER',
    entry: `${origin}/platform-maker/index.html`,
    activeRule: '/pro/platform-maker',
  },
  {
    name: 'EIMOS-DATA-GOVERNANCE',
    entry: `${origin}/data-governance/index.html`,
    activeRule: '/pro/data-governance',
  },
  {
    name: 'EIMOS-DATA-EXPLORATION',
    entry: `${origin}/data-exploration/index.html`,
    activeRule: '/pro/data-exploration',
  },
  {
    name: 'EIMOS-ORCHESTRATION',
    entry: `${origin}/orchestration/index.html`,
    activeRule: '/pro/orchestration',
  },
  {
    name: 'EIMOS',
    entry: `${origin}/eimos/index.html`,
    activeRule: '/pro/eimos',
  },
  // {
  //   name: 'EIMOS-APPSTORE',
  //   entry: `${origin}/appStore/index.html`,
  //   activeRule: '/pro/appStore',

  //   // props: {
  //   //   pageConfig: {
  //   //     get route() {
  //   //       return `${location.pathname}${location.search}`;
  //   //     },
  //   //   },
  //   //   IS_TAB_MICRO_APP: true,
  //   // },
  // },
].map(getEntry);

export const findMicroAppByPath = (path: string) => {
  return (
    microAppList.find((item) => {
      const activeRule = item.activeRule;
      return path.startsWith(activeRule as string);
    }) ??
    getEntry({
      name: 'EIMOS-APPSTORE',
      entry: `${origin}/appStore/index.html`,
      activeRule: '/pro/appStore',
      // container: '#appstudio-container',
      // entry: `http://local.dev.eimos.com:7777/appStore/index.html`,
    })
  );
};

window.eimos = window.eimos || ({} as any);
if (!window.eimos?.debugEntry) {
  window.eimos!.debugEntry = {} as any;
  const entries = [
    ...microAppList,
    {
      name: 'EIMOS-APPSTORE',
      entry: `${origin}/appStore/index.html`,
      activeRule: '/pro/appStore',
      // container: '#appstudio-container',
      // entry: `http://local.dev.eimos.com:7777/appStore/index.html`,
    },
  ].reduce((pre, item) => {
    pre[(item.activeRule as string).replace('/pro/', '')] = (open: boolean, port?: number) => {
      if (!port) {
        log.warn('debugEntry', '您未添加端口号，默认为 7777');
      }
      const key = `eimos:entry:${item.name}`;
      if (open === false) {
        localStorage.removeItem(key);
        return;
      }
      const oldEntry = (item.entry as string).replace(origin, '');
      localStorage.setItem(
        key,
        `${location.protocol}//${
          location.hostname.startsWith('local') ? location.hostname : `local.${location.hostname}`
        }:${port || 7777}${oldEntry}`,
      );
    };
    return pre;
  }, Object.create({}));
  Object.assign(window.eimos!.debugEntry, entries);
}
