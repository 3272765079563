type EnvType = ['lcpTask', 'lcpModel'];

export const getJudgeRoute = (type: EnvType[number], route: string) => {
  if (type == 'lcpTask') {
    return /^(\/pro)?(\/appStore)?(\/lcp)?\/task\//.test(route);
  }
  if (type == 'lcpModel') {
    return /^(\/pro)?(\/appStore)?(\/lcp)?\/vm\//.test(route);
  }
};
