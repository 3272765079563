export type MonitorConfig = {
  aid?: string | number;
  token?: string;
  user: {
    id: any;
    username: string;
  };
  debug: boolean;
  enabled: boolean;
  tracesSampleRate?: number;
  replaysSessionSampleRate?: number;
  replaysOnErrorSampleRate?: number;
  dsn?: string;
  type: 'sentry' | 'apm';
  release?: string;
  env?: string;
};

type Config = {
  enable: boolean;
  type: 'apm' | 'sentry';
};

export type CaptureOptions = {
  level: 'info';
  tags: Record<string, string>;
};

abstract class MonitorIns {
  abstract start(): void;
  abstract init(config: MonitorConfig): void;
  abstract initScript(): Promise<void>;
  abstract info(eventName: string, options: Partial<Omit<CaptureOptions, 'level'>>): any;
}

class ApmMonitorIns extends MonitorIns {
  start() {
    window.apmPlus('start');
  }
  async init(options: MonitorConfig) {
    window.apmPlus('init', {
      aid: options.aid,
      token: options.token,
      userId: `${options.user.id}`,
      release: options.release,
      env: options.env,
    });
  }

  async initScript() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await import('./scripts/apm.js');
  }

  info(eventName: string, options: Partial<Omit<CaptureOptions, 'level'>>) {
    console.log('todo 事件上报 eventName', eventName, options);
    // const { tags, ...rest } = options;
    // window.apmPlus('init', {
    //   aid: options.aid,
    //   token: options.token,
    //   userId: `${options.user.id}`,
    //   release: options.release,
    //   env: options.env,
    // })
  }
}

class SentryMonitorIns extends MonitorIns {
  start() {
    //
  }
  init(options: MonitorConfig) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const { user, ..._options } = options;
    const sentryOption = {
      dsn: _options.dsn,
      // Performance Monitoring
      tracesSampleRate: _options.tracesSampleRate || 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: _options.replaysSessionSampleRate || 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: _options.replaysOnErrorSampleRate || 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    };
    this.Sentry.init({
      ...sentryOption,
      integrations: [
        new this.Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        }),
        new this.Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
    });
    this.Sentry.setUser(user);

    // debugger;
    // if (typeof user === 'function') {
    //   window.Sentry.setUser(options.user);
    // }
  }

  info(title: string, options: Partial<Omit<CaptureOptions, 'level'>>) {
    const { tags, ...rest } = options;
    this.Sentry.captureMessage(title, {
      level: 'info',
      tags: {
        page: window.location.pathname,
        ...tags,
      },
      ...rest,
    });
  }

  private Sentry: any;

  async initScript() {
    const [Sentry] = await Promise.all([import('@sentry/browser')]);
    this.Sentry = Sentry;
  }
}

export function enabled() {
  return function (_: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value;
    descriptor.value = function (this: Monitor, ...args: any[]) {
      if (!this.enabled) {
        return;
      }
      const ret = original.call(this, ...args);
      return ret;
    };
  };
}

export class Monitor {
  enabled = true;
  ins!: MonitorIns;
  constructor(private config: Config) {
    this.enabled = !!config.enable;
    if (this.config.type === 'apm') {
      this.ins = new ApmMonitorIns();
    } else if (this.config.type === 'sentry') {
      this.ins = new SentryMonitorIns();
    } else {
      throw new Error('type 错误');
    }
  }

  @enabled()
  async init(monitorConfig: MonitorConfig) {
    await this.ins.initScript();
    const release = window.BUI_BUILD_VERSION?.eimos?.version;
    const env = location.hostname;
    this.ins.init({ release, env, ...monitorConfig });
  }

  @enabled()
  start() {
    this.ins.start();
  }

  info(eventName: string, options: Partial<Omit<CaptureOptions, 'level'>>) {
    this.ins.info(eventName, options);
  }
}
