
      try {
        window.BUI_BUILD_VERSION = window.BUI_BUILD_VERSION || {};
        window.BUI_BUILD_VERSION["eimos"] = window.BUI_BUILD_VERSION["eimos"] || {};
        window.BUI_BUILD_VERSION["eimos"].commitId = "6b7e091";
        window.BUI_BUILD_VERSION["eimos"].branchName = "HEAD";
        window.BUI_BUILD_VERSION["eimos"].version = "PDT_20240714";
        window.BUI_BUILD_VERSION["eimos"].date = "2024-8-22 17:2:10";
      } catch (e) {
        console.error('BuiBuildVersion error');
        console.error(e);
      }
    