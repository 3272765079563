import React, { useEffect, useRef, useState } from 'react';
import './index.module.less';
import { useMemoizedFn } from 'ahooks';
export const SuspendButton = () => {
  const [maskClassName, setMaskClassName] = useState('');
  const buttonRef = useRef<HTMLDivElement | null>(null);

  const state = useRef({
    oLeft: 0,
    oTop: 0,
    moving: false,
    oW: 0,
    oH: 0,
    htmlWidth: 0,
    htmlHeight: 0,
    bWidth: 0, // 悬钮宽度
    bHeight: 0,
    click: false, // 是否是点击
    boxStyles: {
      width: '40px',
      height: '40px',
    },
  });
  const onTouchStart: React.TouchEventHandler<HTMLDivElement> = useMemoizedFn((_e) => {
    _e.stopPropagation();
    if (maskClassName) {
      return;
    }
    const e = _e.touches[0];
    state.current.click = true;

    state.current.oW = e.clientX - buttonRef.current!.getBoundingClientRect().left;
    state.current.oH = e.clientY - buttonRef.current!.getBoundingClientRect().top;

    state.current.htmlWidth = document.documentElement.clientWidth;
    state.current.htmlHeight = document.documentElement.clientHeight;
    state.current.bWidth = buttonRef.current!.offsetWidth;
    state.current.bHeight = buttonRef.current!.offsetHeight;

    const oLeft = e.clientX - state.current.oW;
    const oTop = e.clientY - state.current.oH;
    state.current.oLeft = oLeft;
    state.current.oTop = oTop;
    buttonRef.current!.style.left = oLeft + 'px';
    // buttonRef.current!.style.top = oTop + 'px';
    state.current.moving = true;
  });

  useEffect(() => {
    const onTouchMove = (_e: TouchEvent) => {
      _e.stopPropagation();
      _e.preventDefault();
      buttonRef.current!.className = 't-suspend-button';
      const e = _e.touches[0];
      if (state.current.moving) {
        state.current.click = false;

        // 左侧距离
        let oLeft = e.clientX - state.current.oW;
        let oTop = e.clientY - state.current.oH;
        if (oLeft < 0) {
          oLeft = 0;
        } else if (oLeft > state.current.htmlWidth - state.current.bWidth) {
          oLeft = state.current.htmlWidth - state.current.bWidth;
        }
        if (oTop < 0) {
          oTop = 0;
        } else if (oTop > state.current.htmlHeight - state.current.bHeight) {
          oTop = state.current.htmlHeight - state.current.bHeight;
        }
        state.current.oLeft = oLeft;
        state.current.oTop = oTop;
        buttonRef.current!.style.right = 'auto';
        buttonRef.current!.style.left = oLeft + 'px';
        buttonRef.current!.style.top = oTop + 'px';
      }
    };
    buttonRef.current!.addEventListener('touchmove', onTouchMove, { passive: false });
    return () => {
      buttonRef.current?.removeEventListener('touchmove', onTouchMove);
    };
  }, []);

  const onTouchEnd = useMemoizedFn(() => {
    state.current.moving = false;

    buttonRef.current!.className = buttonRef.current!.className + ' t-suspend-button-animate';

    // 左侧距离
    let oLeft = state.current.oLeft;
    if (oLeft < (state.current.htmlWidth - state.current.bWidth) / 2) {
      oLeft = -4;
    } else {
      oLeft = state.current.htmlWidth - state.current.bWidth;
      // 图片边框
      oLeft -= 18;
    }

    state.current.oLeft = oLeft;
    buttonRef.current!.style.left = oLeft + 'px';

    if (state.current.click && !maskClassName) {
      buttonRef.current!.style.width = '100%';
      buttonRef.current!.style.height = 'auto';
      buttonRef.current!.style.background = '#0064ce';
      buttonRef.current!.style.left = '0px';
      buttonRef.current!.style.zIndex = '999';
      setMaskClassName('bg');
    }
  });

  const onGoHome = () => {
    const path = '/eimos-h5/';
    location.href = `${location.origin}${path}`;
  };

  const onMaskClick = () => {
    setMaskClassName('');
    buttonRef.current!.style.width = '40px';
    buttonRef.current!.style.height = '40px';
    buttonRef.current!.style.left = state.current.oLeft + 'px';
    // buttonRef.current!.style.top = state.current.oTop + 'px';
    buttonRef.current!.style.background = 'none';
  };

  return (
    <div>
      <div className={maskClassName} onClick={onMaskClick}></div>
      <div
        ref={buttonRef}
        className="t-suspend-button"
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      >
        <div className="floatModal">
          <img src={require('./imgs/home.png')} alt="" width="64px" height="64px" />
          <p style={{ display: maskClassName ? 'block' : 'none' }} onClick={onGoHome}>
            返回主页
          </p>
        </div>
      </div>
    </div>
  );
};
