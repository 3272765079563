import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './index.module.less';
/**
 * 处理仅 eimos 自己拥有的页面的布局
 */
export const EimosLayout = () => {
  return (
    <div className={styles['eimos-eimos-container']}>
      <Outlet />
    </div>
  );
};
