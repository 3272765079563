/**
 * @param {open} 控制Drawer显示
 *  onCancel 关闭回调
 *  title 标题
 *  width 宽度
 *  height 高度
 *  zIndex zIndex
 *  placement 抽屉方向
 *  mask 是否展示遮罩
 *  maskClosable 点击遮罩是否关闭
 *  closable 是否显示右上角关闭按钮
 *  destroyOnClose 关闭抽屉销毁子元素
 *  getContainer 指定 Drawer 挂载的 HTML 节点, 可以将抽屉挂载在任何元素上
 *  drawerStyle 能自定义抽屉弹出层样式
 *  position 方向
 */
import React from 'react';
import { useState, useEffect } from 'react';
import styles from './index.module.less';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { Resizable } from 're-resizable';
import { CloseIcon } from './components';
import { usePosition } from './effect';

export interface IpropsDrawer {
  open: boolean;
  title: React.ReactNode;
  width?: string;
  height?: string;
  onCancel: () => void;
  zIndex: number;
  contentZindex: number;
  placement: string;
  mask: boolean;
  maskClosable: boolean;
  closable: boolean;
  destroyOnClose: boolean;
  getContainer: HTMLElement | false;
  drawerStyle: any;
  position?: 'right' | 'bottom';
  className?: string;
}
type Iprops = IpropsDrawer;

const TelescopicDrawer: React.FC<Partial<Iprops>> = (props) => {
  const {
    open,
    title = '',
    closable = true,
    height: defaultHeight,
    onCancel,
    zIndex = 1000,
    contentZindex,
    placement = 'right',
    mask = true,
    maskClosable = true,
    destroyOnClose = true,
    getContainer = document.body,
    drawerStyle,
    className,
  } = props;

  const { drawerlen, setDrawerlen, resizebleConfig } = usePosition({ ...props });

  // 控制关闭弹框清空弹框里面的元素
  const [clearContentDom, setClearContentDom] = useState(false);

  // 控制drawer 的显示隐藏
  const [drawerVisible, setDrawerVisible] = useState(open);
  useEffect(() => {
    setDrawerVisible(() => {
      if (getContainer !== false && open) {
        getContainer.style.overflow = 'hidden';
      }
      return open;
    });
    if (open) {
      setClearContentDom(false);
    }
  }, [open]);

  // 点击弹框关闭
  const handleClose = () => {
    setDrawerVisible((prev) => {
      if (getContainer !== false && prev) {
        getContainer.style.overflow = 'hidden';
      }
      return false;
    });
    onCancel && onCancel();
    if (destroyOnClose) {
      setClearContentDom(true);
    }
  };

  const resizableComponent = (
    <Resizable
      className={classnames(
        className,
        styles['drawerContent'],
        !drawerVisible ? styles['closeDrawer'] : '',
      )}
      style={{
        ...resizebleConfig.style,
        [placement]: 0,
        ...drawerStyle,
        zIndex: contentZindex,
      }}
      size={resizebleConfig.size}
      onResizeStop={(e, direction, ref, d) => {
        setDrawerlen(drawerlen + (d as any)[resizebleConfig.flexKey]);
      }}
      enable={resizebleConfig.enable}
      {...resizebleConfig.props}
    >
      {title && <div className={styles['titleDrawer']}>{title}</div>}
      <div className={styles['render']}>{clearContentDom ? null : props.children}</div>
      {closable && (
        <span className={styles['closeDrawerBtn']} onClick={handleClose}>
          <CloseIcon />
        </span>
      )}
    </Resizable>
  );

  const drawerDom = mask ? (
    <div
      className={styles['drawerWarp']}
      style={{
        width: drawerVisible ? '100%' : '0',
        height: defaultHeight,
        zIndex,
        position: getContainer === false ? 'absolute' : 'fixed',
      }}
    >
      <div
        className={styles['drawerMask']}
        style={{ opacity: drawerVisible ? 1 : 0 }}
        onClick={maskClosable ? handleClose : undefined}
      ></div>
      {resizableComponent}
    </div>
  ) : (
    resizableComponent
  );

  // getContainer 默认你是body, 如果为 false 则挂在最近的父节点上， 如果自己传入一个要
  // 挂在的对象下，则需要用到  ReactDOM.createPortal

  return getContainer === false && !getContainer
    ? drawerDom
    : ReactDOM.createPortal(drawerDom, getContainer);
};

export default TelescopicDrawer;
