class Prefetch {
  stack: Array<(...args: any[]) => any> = [];
  add(callback: (...args: any[]) => any) {
    this.stack.push(callback);
  }

  batch(callbacks: Array<(...args: any[]) => any>) {
    this.stack.push(...callbacks);
  }

  start() {
    this.stack.forEach((callback) => {
      callback();
    });
  }
}

export const prefetch = new Prefetch();
