import React from 'react';

import { Navigate, type RouteObject } from 'react-router-dom';
import * as Pages from './pages';
import { getHomeLink } from './infra';
import { appName } from './config';
import { EimosLayout } from './layout/EimosLayout';

let homeLink = getHomeLink();
console.log('homeLink', homeLink);
if (homeLink === '/') {
  homeLink = '/pro/portal/home';
}
console.log('homeLink', homeLink);
const routeObjects: RouteObject[] = [
  { index: true, element: <Navigate to={homeLink} replace /> },
  { path: '/pro', element: <Navigate to={homeLink} replace /> },
  {
    path: `/${appName}`,
    element: <Navigate to={homeLink} replace />,
    children: [{ path: '*', element: null }],
  },
  {
    path: `/pro`,
    element: <Pages.Pro />,
    children: [{ path: '*', element: null }],
  },
  {
    path: `/eimos`,
    element: <EimosLayout />,
    children: [
      { path: 'portal/profile', element: <Pages.Profile /> },
      { path: 'usercenter/systemConfig', element: <Pages.SystemConfig /> },
      { path: 'usercenter/systemh5Config', element: <Pages.Systemh5Config /> },
      { path: 'usercenter/preferenceConfig', element: <Pages.PreferenceConfig /> },
      {
        path: 'usercenter/noticeManage/create',
        element: <Pages.NoticeManage />,
      },
      {
        path: 'usercenter/noticeManage/edit',
        element: <Pages.NoticeManage />,
      },
      {
        path: 'usercenter/funcManage',
        element: <Pages.MenuManage />,
      },
      {
        path: 'usercenter/thirdPartyConfig',
        element: <Pages.ThirdPartyConfig />,
      },
    ],
  },
  {
    path: '/eimos/editor/preview/:id',
    element: <Pages.EditorPreview />,
  },
  {
    path: '/eimos/editor/list',
    element: <Pages.EditorList />,
  },
  {
    path: '/eimos/editor/detail/:type',
    element: <Pages.EditorPage />,
  },
  {
    path: '/eimos/editor/detail/:type/:recordID',
    element: <Pages.EditorPage />,
  },
  {
    path: '/eimos/microPage/:microAppId',
    element: <Pages.MicroPage />,
  },
  {
    path: '/eimos/microPage/create',
    element: <Pages.CreateMicroPage />,
  },
  {
    path: '/eimos/workPlatForm',
    element: <Pages.WorkPlatForm />,
  },
  {
    path: '/eimos/servicePage',
    element: <Pages.ServicePage />,
  },
  { path: '*', element: <Pages.NotFound /> },
];

export default routeObjects;
