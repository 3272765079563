import { Layout as AntLayout } from 'antd';
import React from 'react';
import cx from 'classnames';
import styles from './index.module.less';
import { appName, isQiankun } from '@/config';
import { isMobile } from '@/utils/isMobile';
import { usePreferenceByKey } from '@/hooks/usePreferenceConfig';
const { Sider, Content } = AntLayout;

type Props = {
  top: React.ReactNode;
  side: React.ReactNode;
  children: React.ReactNode;
  siderWidth?: number;
};

export const AppLayout = (props: Props) => {
  const [layoutConfig] = usePreferenceByKey('layout');
  const isMobileDevice = isMobile();
  const simpleLayout = layoutConfig === 'simple';
  const renderSide = () => {
    if (simpleLayout || isQiankun) {
      return null;
    }
    return (
      <Sider width={props.siderWidth} className={styles['side-wrapper']}>
        {props.side}
      </Sider>
    );
  };

  return (
    <AntLayout
      className={cx(
        styles.layout,
        isQiankun && styles['standalone'],
        isMobileDevice && styles['is-mobile'],
      )}
    >
      {isMobileDevice ? null : props.top}
      {isMobileDevice ? null : renderSide()}
      <AntLayout
        className={styles['layout-content-wrapper']}
        style={simpleLayout ? { overflow: 'auto' } : {}}
      >
        <Content>
          <div className={`sp-${appName}-content`} style={{ height: '100%' }}>
            {props.children}
          </div>
        </Content>
      </AntLayout>
    </AntLayout>
  );
};
