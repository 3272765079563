export function escapeRegExp(reg: string) {
  const escapedPattern = reg.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  return new RegExp(escapedPattern);
}

export class QueryString {
  parsedUrl: URL | undefined;
  constructor() {
    //
  }

  parseUrl(url: string) {
    if (!url.startsWith('http')) {
      if (!url.startsWith('/')) {
        url = '/' + url;
      }
      url = `${location.protocol}//${location.host}${url}`;
    }
    this.parsedUrl = new URL(url);
    return this;
  }

  private checkInit() {
    if (!this.parsedUrl) {
      throw new Error('');
    }
  }
  addQuery(query: Record<string, any>) {
    this.checkInit();
    Object.keys(query).forEach((key) => {
      this.parsedUrl!.searchParams.set(key, query[key]);
    });
    return this;
  }

  hasQuery(key: string) {
    this.checkInit();
    this.parsedUrl!.searchParams.has(key);
  }
  getQuery(key: string) {
    this.checkInit();
    return this.parsedUrl!.searchParams.get(key);
  }
  /**
   * 获取完整 url
   */
  getUrl() {
    this.checkInit();
    return this.parsedUrl!.toString();
  }
}

export const addQuery = (url: string, newQuery: Record<string, any>) => {
  const parsedUrl = new QueryString();
  parsedUrl.parseUrl(url);
  parsedUrl.addQuery(newQuery);
  return parsedUrl.toString();
};

export const formatUrl = <T extends string | RegExp>(u: T) => {
  if (typeof u === 'string') {
    return u
      .replace(/^(?:https?:\/\/)?([^/]+)/gi, '')
      .replace(/\/$/, '')
      .replace(/\?.+$/, '');
    // .replace(/^(\/pro)?(\/appStore)?/, '');
  } else if (u instanceof RegExp) {
    return u;
  }
  throw new Error('类型错误');
};

export const createHistoryIsolateEvent = <T extends keyof History>(
  type: T,
  /**
   * 拦截器，如果返回 false，则不执行跳转
   */
  handle: (nextUrl: string, curUrl: string) => string | false,
) => {
  // const isolateCodeReg = escapeRegExp(isolateCode);
  const origin = history[type];

  return function (this: any, ...args: any[]) {
    const [state, unused, _url, ...rest] = args;
    // 当 url 为空，则表示通过页面刷新第一次进入页面
    const url = _url || location.href;
    const nextUrl = handle(url, location.href);
    if (nextUrl === false) {
      return;
    }
    const res = origin.apply(this, [state, unused, nextUrl, ...rest]);
    return res;
  };
};
