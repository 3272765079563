import { isQiankun } from '@/config';
import { BuiReport } from '@bui/bui-report';
let reporter: BuiReport;
if (!(window.eimos as any)?.reporter) {
  const reporter = BuiReport.createInstance().init({
    open: !isQiankun,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    user: window.eimos._userInfoDeferred.promise,
    appId: 'eimos',
    version: process.env.APP_VERSION!,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.eimos.reporter = reporter;
}
export { reporter };
