import React, { useEffect, useRef } from 'react';
import QiankunModal from '../QiankunModal';
import { useMemoizedFn } from 'ahooks';
// import { useNotifyEvent } from '@/hooks/useAppStoreEvent';
// import { UseStoreType } from '@/store/type';
import { log } from '@/utils/log';
import { useQiankunModalStore, useQiankunModals } from '@/hooks/useQiankunModalStore';
import { UI_TYPES } from '../QiankunModal/type';

export const QiankunModals = () => {
  const modals = useQiankunModals();

  const refs = useRef<(Record<string, any> | null)[]>([]);

  useEffect(() => {
    modals.forEach((modal, index: number) => {
      refs.current[index]?.show();
    });
  }, [modals]);

  const onHide = (modal: any) => {
    modal.deferredResult?.resolve();
    useQiankunModalStore.getState().close(modal.id);
    console.log('onhide modal', modal);
  };

  /**
   * @deprecated
   */
  const saveSuccess = () => {
    log.warn('AppQiankun', 'saveSuccess 即将废弃，请使用新版本 modal 动作配置');
    // (appQiankunConfig as any)?.events?.saveSuccess?.();
  };

  const notify = useMemoizedFn(() => {
    // notifyEvent?.(...args);
  });

  /**
   * @deprecated
   */
  const updateModalCloseState = () => {
    log.warn('AppQiankun', 'updateModalCloseState 即将废弃，请使用新版本 modal 动作配置');
  };

  return (
    <>
      {modals.map((modal, index: number) => (
        <QiankunModal
          key={`QiankunModal-${index}`}
          props={modal.props}
          ref={(el) => {
            refs.current[index] = el;
          }}
          containerDom={
            typeof modal.containerDom === 'function'
              ? modal.containerDom()
              : (document.querySelector(modal.containerDom) as any) || document.body
          }
          type={modal.type as UI_TYPES}
          route={modal.route}
          qiankunId={modal.id}
          drawerDirection={modal.drawerDirection}
          defaultHeight={modal.defaultHeight}
          defaultWidth={modal.defaultWidth}
          title={modal.title}
          onHide={() => {
            onHide(modal);
          }}
          onOk={(value) => {
            useQiankunModalStore.getState().close(modal.id, value);
            modal.deferredResult?.resolve(value);
          }}
          notify={notify}
          saveSuccess={saveSuccess}
          customOnClose={updateModalCloseState}
        />
      ))}
    </>
  );
};
