import { useMemoizedFn } from 'ahooks';
import { useEffect } from 'react';

export const copy = (value: string) => {
  const textarea = document.createElement('textarea');
  textarea.value = value;

  // 将 textarea 元素添加到 DOM 中（可选，但必须添加到 DOM 才能执行复制操作）
  document.body.appendChild(textarea);

  // 选中文本并执行复制操作
  textarea.select();
  document.execCommand('copy');

  // 移除 textarea 元素（可选）
  document.body.removeChild(textarea);
};

type Props = {
  onPaste(value: string): void;
};

export const usePaste = (props: Props) => {
  const onEvent = useMemoizedFn((data: string) => {
    if (!data) {
      return;
    }
    props.onPaste(data);
  });

  const onCopy = useMemoizedFn((value: string) => {
    try {
      copy(value);
    } catch (e) {
      console.error('复制失败', e);
    }
  });

  useEffect(() => {
    const onPaste = function (event: ClipboardEvent) {
      const text = event.clipboardData?.getData('text');
      try {
        if (text) {
          onEvent(text);
        }
      } catch (e) {
        console.error('动作粘贴失败', e);
      }
    };
    document.addEventListener('paste', onPaste);
    return () => {
      document.removeEventListener('paste', onPaste);
    };
  }, []);

  return [onCopy] as const;
};
