import dayjs from 'dayjs';
import * as uuid from 'uuid';
import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';
import { Deferred } from '@/utils';

export const random = () => {
  return uuid.v4().slice(0, 8);
};
export enum ChatType {
  TEXT = 'text',
  CONFIRM = 'confirm',
  IMAGE = 'image',
  FILE = 'file',
  INITINFO = 'initInfo',
  LOADING = 'loading',
  INITFUNC = 'initFunc',
}

export const transferText = (text: string, isMe: boolean, type?: ChatType) => {
  const date = dayjs().unix();
  return {
    id: random(),
    timestamp: date,
    isSelf: isMe,
    type: type ?? ChatType.TEXT,
    value: text,
    detail: {
      content: text,
    },
  };
};

export type FastGPTConfig = {
  chatId: string;
  robotCode: string;
};
type FastGPTData = {
  id: string;
  timestamp: number;
  isSelf: boolean;
  type: ChatType;
  value: string;
  detail: {
    content: string;
  };
};

export class FastGPT {
  config!: FastGPTConfig;
  init(config: FastGPTConfig) {
    this.config = config;
  }
  public question(config: FastGPTConfig, question: string): Promise<FastGPTData>;
  public question(config: FastGPTConfig & { question: string }): Promise<FastGPTData>;
  public question(question: string): Promise<FastGPTData>;
  public async question(...args: any[]) {
    let question;
    if (args.length === 2) {
      this.config = { ...this.config, ...args[0] };
      question = args[1];
    } else if (args.length === 1) {
      if (typeof question === 'string') {
        question = args[0];
      } else {
        this.config = {
          ...this.config,
          chatId: args[0].chatId,
          robotCode: args[0].robotCode,
        };
        question = args[0].question;
      }
    } else {
      throw new Error('参数格式错误');
    }

    if (!this.config) {
      throw new Error('请 调用 init() 初始化 config 信息');
    }
    if (!this.config.chatId) {
      this.config.chatId = random();
    }
    /** 传一条loading */
    // const loading = transferText('', false, ChatType.LOADING);
    // this.set({ chatList: [...this.get().chatList, loading] });

    // const text = '';
    // const self = this;
    // if (getInfoRef) {
    //   text = getInfoRef.current();
    // }

    let output = '';
    let ans = transferText(output, false);
    let chunkText = '';
    let chunkData: Record<string, any>;
    let falseFlag = false;
    // 上一条断尾了 made写的狗屎接口害人
    let lastFlag = false;
    const ctrl = new AbortController();
    const deferred = new Deferred<FastGPTData>();

    fetchEventSource('/api/gateway/metabase/feign/ai/fastgpt/streamChat', {
      method: 'POST',
      headers: {
        accept: 'text/event-stream',
        'accept-language': 'zh-CN,zh;q=0.9',
        'cache-control': 'no-cache',
        'content-type': 'application/json',
        pragma: 'no-cache',
        'request-origion': 'Knife4j',
        // 'x-xsrf-token': '1373e19a-8d87-402f-87e4-71a2105fb10d',
      },
      openWhenHidden: true,
      body: JSON.stringify({
        robotCode: this.config.robotCode,
        question: question || '',
        chatId: this.config.chatId,
      }),
      signal: ctrl.signal,

      async onopen(response) {
        if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
          return; // everything's good
        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
          // client-side errors are usually non-retriable:
          console.error('未找到服务');
          falseFlag = true;
          return;
        } else {
          return;
        }
      },
      //  狗屎接口害的要处理
      onmessage(ev: any) {
        if (!(ev.data as string).startsWith('data:')) {
          try {
            if (lastFlag) {
              // 接断头后处理
              chunkData = JSON.parse(chunkText);
              output += chunkData?.choices?.[0]?.delta?.content || '';
              chunkText = '';
              // self.updateStream(chunkData, output, ans);
              lastFlag = false;
            }
            // 单独一条
            chunkData = JSON.parse(ev.data);
            output += chunkData?.choices?.[0]?.delta?.content || '';
            chunkText = '';
            // self.updateStream(chunkData, output, ans);
          } catch (e) {
            // 断头的拼接
            chunkText += ev.data;
            lastFlag = true;
          }
        } else {
          // 叠加
          chunkText += (ev.data as string)?.replace('data: ', '');
        }
      },

      onerror(err: any) {
        falseFlag = true;
        console.log('传输错误:', err);
        return;
      },
      onclose() {
        if (falseFlag) {
          deferred.reject();
          return;
        }
        // const extra_content = transferInfoToJSX(setInfoRef?.current || [], output);
        ans = {
          ...ans,
          value: output,
          detail: {
            // extra_content,
            content: output,
            // loading: false,
          },
        };
        deferred.resolve(ans);
        // self.set((state) => {
        //   state.chatList[state.chatList.length - 1] = ans;
        //   state.curFn = [];
        // });
      },
    });

    return await deferred.promise;
  }
}

export const fastGPT = new FastGPT();
