export class Deferred<T> {
  private _promise: Promise<T>;
  private _resolve: ((value?: T | PromiseLike<T>) => void) | null = null;
  private _reject: ((reason?: any) => void) | null = null;

  constructor() {
    this._promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve as any;
      this._reject = reject;
    });
  }

  get promise(): Promise<T> {
    return this._promise;
  }

  resolve(value?: T | PromiseLike<T>): void {
    if (this._resolve) {
      this._resolve(value);
    }
  }

  reject(reason?: any): void {
    if (this._reject) {
      this._reject(reason);
    }
  }
}
