import React from 'react';
// import styles from './index.module.less';
import { Empty } from 'antd';

const NoPermission: React.FC = () => {
  return (
    <div
      style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Empty description="暂无权限" />
    </div>
  );
};

export default NoPermission;
