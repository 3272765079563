import { lazy } from 'react';
import NotFound from './NotFound';
export const Pro = lazy(() => import(/* webpackChunkName: "page_Pro" */ './Pro'));
export const Profile = lazy(
  () => import(/* webpackChunkName: "page_Profile" */ './Portal/Profile'),
);
export const SystemConfig = lazy(
  () => import(/* webpackChunkName: "page_UserCenter" */ './UserCenter/SystemConfig'),
);
// export const Login = lazy(() => import('./Login'));
export const NoticeManage = lazy(
  () => import(/* webpackChunkName: "page_UserCenter" */ './UserCenter/NoticeManage'),
);
export const PreferenceConfig = lazy(
  () => import(/* webpackChunkName: "page_UserCenter" */ './UserCenter/PreferenceConfig'),
);
export const Systemh5Config = lazy(
  () => import(/* webpackChunkName: "page_UserCenter" */ './UserCenter/Systemh5Config'),
);

export const EditorPage = lazy(
  () => import(/* webpackChunkName: "page_PrintEditor" */ './PrintEditor/Editor'),
);
export const EditorPreview = lazy(
  () => import(/* webpackChunkName: "page_PrintEditor" */ './PrintEditor/EditorPreview'),
);
export const EditorList = lazy(
  () => import(/* webpackChunkName: "page_PrintEditor" */ './PrintEditor/EditorList'),
);
export const MenuManage = lazy(() => import('./UserCenter/MenuManage'));
export const Login = lazy(() => import('./Login'));
export const MicroPage = lazy(() => import('./MicroPage'));
export const CreateMicroPage = lazy(() => import('./MicroPage/create'));
export const WorkPlatForm = lazy(() => import('./WorkPlatform'));
export const ServicePage = lazy(() => import('./ServicePage'));
export const ThirdPartyConfig = lazy(() => import('./UserCenter/ThirdPartyConfig'));

export { NotFound };
