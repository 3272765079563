import React from 'react';
import styles from './index.module.less';
import cx from 'classnames';

export default function Loading() {
  return (
    <div className={styles['spin-indicator-container']}>
      <div className={cx(styles['loading-circle'], styles['loading-animation1'])} />
      <div className={cx(styles['loading-circle'], styles['loading-animation2'])} />
      <div className={cx(styles['loading-circle'], styles['loading-animation3'])} />
    </div>
  );
}
