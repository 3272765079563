import { log } from '@/infra';
import * as uuid from 'uuid';

export * from './deferred';

export const random = () => {
  return uuid.v4().slice(0, 8);
};

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * 生成 8 位随机 id
 */
export const randomId = () => {
  const id = uuid.v4();
  // 提取前8位字符作为随机数
  return id.substring(0, 8);
};

export const urlIsEqual = (url1: string, url2: string) => {
  const removeAppCodeReg = /(\??|&?)appCode=([a-zA-Z0-9_]+)/;
  const removeFilterProcessId = /(\??|&?)filterProcessId=([a-zA-Z0-9_]+)/;
  return (
    decodeURIComponent(url1.replace(removeAppCodeReg, '').replace(removeFilterProcessId, '')) ===
    decodeURIComponent(url2.replace(removeAppCodeReg, '').replace(removeFilterProcessId, ''))
  );
};

export async function doWithRetry<T>(
  callback: (retry: number) => Promise<T>,
  maxRetries = 3,
  timeout = 3000,
) {
  for (let retry = 0; retry < maxRetries; retry++) {
    try {
      console.time('doWithRetry timer');
      const response = await callback(retry);
      log.info('doWithRetry', `Success on attempt ${retry + 1}`);
      console.timeEnd('doWithRetry timer');
      return response;
    } catch (error) {
      // 请求失败，输出错误信息
      console.timeEnd('doWithRetry timer');
      log.error('doWithRetry', `Error on attempt ${retry + 1}: ${error}`);
    }

    // 等待一段时间后进行重试
    await new Promise((resolve) => setTimeout(resolve, timeout));
  }

  // 重试次数超过限制，抛出错误或返回默认值
  throw new Error(`Failed to fetch microApp after ${maxRetries} attempts`);
}

export function doWithTimeout<T>(callback: (...args: any[]) => Promise<T>, timeout = 3000) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<T>(async (resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error('doWithTimeout timed out'));
    }, timeout);

    try {
      const response = await callback();
      clearTimeout(timeoutId); // 清除超时计时器
      resolve(response);
    } catch (error) {
      reject(new Error(`doWithTimeout failed: ${error}`));
    }
  });
}
