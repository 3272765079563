import Store, { BaseStore } from 'zustand-store';

import { buildQueryString } from '@/utils/url';
import { Deferred, randomId } from '@/utils';
import { useMemo } from 'react';
import { log } from '@/utils/log';

/**
 * 弹窗事件类型
 */
export type ModalActionType =
  | 'drawer'
  | 'modal'
  | 'rightTelescopicDrawer'
  | 'bottomSuctionDrawer'
  | 'closeDrawerOrModal';

type ModalId = string;

/**
 * 弹窗配置
 */
type ModalConfig = {
  /**
   * 弹窗动作类型
   */
  type: ModalActionType;
  /**
   * 一般使用 group id
   */
  id: ModalId;
  /**
   * 弹窗路由
   */
  route: string;
  /**
   * 是否关闭弹窗
   */
  close?: boolean;
  /**
   * 弹窗插入的 dom
   */
  containerDom: () => Element;
  /**
   * 弹窗默认高度
   */
  defaultHeight?: string;
  /**
   * 弹窗默认宽度
   */
  defaultWidth?: string;
  /**
   * 弹窗标题
   */
  title: string;
  /**
   * 传给 modal 的参数，作为 props 传给 modal
   */
  props?: Record<string, any>;
  /**
   * 弹窗隐藏 onOk onCancel 时调用
   */
  deferredResult?: Deferred<any>;
  drawerDirection?: 'top' | 'right' | 'bottom' | 'left'; // 打开抽屉的方向
  /**
   * 关闭后的回调函数
   * @param args 第一个参数是确认时的传参
   * @returns
   */
  callback?: (...args: any[]) => void;
};

/**
 * 打开弹窗参数，参数形式兼容低代码 sp.open
 */
export type OpenParams = Pick<
  ModalConfig,
  'id' | 'title' | 'props' | 'deferredResult' | 'containerDom'
> & {
  actionType: ModalActionType | 'tab'; // 打开方式
  url: string; // 打开地址
  params?: Record<string, any>; // 参数
  width?: number | string;
  height?: number | string;
  drawerDirection?: 'top' | 'right' | 'bottom' | 'left'; // 打开抽屉的方向
};

class UseQiankunModalStore extends BaseStore<UseQiankunModalStore> {
  modals: Record<ModalId, ModalConfig> = {};
  private has(modalId: ModalId) {
    return modalId in this.get().modals;
  }
  open(params: OpenParams, callback: (...args: any[]) => void) {
    log.info('useQiankunStore', 'open', params);
    const route = buildQueryString(params.url, params.params);
    if (params.actionType === 'tab') {
      console.log('todo', 'openTab');
      // buiAppStore.openTab({
      //   url: route,
      //   title: params.title,
      // });
      return;
    }
    const modalConfig = {
      type: params.actionType,
      title: params.title,
      props: params.props,
      containerDom: params.containerDom,
      drawerDirection: params.drawerDirection,
      defaultHeight:
        typeof params.height === 'number' ? `${params.height}px` : String(params.height),
      defaultWidth: typeof params.width === 'number' ? `${params.width}px` : String(params.width),
      route,
      id: params.id || randomId(),
      deferredResult: params.deferredResult,
      callback,
    };
    // 如果存在则更新
    this.set((state) => {
      state.modals[modalConfig.id] = modalConfig;
    });
  }

  close(modalId: ModalId, value?: any) {
    const modal = this.get().modals[modalId];
    modal?.callback?.(value);
    if (this.has(modalId)) {
      this.set((state) => {
        delete state.modals[modalId];
      });
    }
  }
}

export const useQiankunModalStore = Store.create(UseQiankunModalStore);

export const useQiankunModals = () => {
  const modalsMap = useQiankunModalStore((state) => state.modals);
  return useMemo(() => {
    return Object.keys(modalsMap).map((key) => {
      return modalsMap[key];
    });
  }, [modalsMap]);
};
