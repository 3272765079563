import { useTranslation } from '@bui/i18n';
import { useCallback } from 'react';

export const useTranslate = () => {
  const [t] = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return useCallback((type: string, fallback?: string) => {
    return t(`eimos.${type}`, fallback);
  }, []);
};
