import { log } from './log';
import { QueryString, createHistoryIsolateEvent, formatUrl } from './utils';

window.__appIsolateInstance = window.__appIsolateInstance || [];

export class AppIsolate {
  public inited = false;
  private unmountStack: Array<() => void> = [];
  isolateCode: string;
  private whitelist: Array<string | RegExp>;
  private whitelistSet: Set<string | RegExp>;

  static getInstances() {
    return window.__appIsolateInstance;
  }

  constructor(params: { whitelist: Array<string | RegExp>; isolateCode: string }) {
    this.isolateCode = params.isolateCode;
    const whitelist = (params?.whitelist || []).map((item) => {
      return formatUrl(item);
    });
    this.whitelist = whitelist;
    this.whitelistSet = new Set(whitelist);
    window.__appIsolateInstance.push(this);
  }

  unmount() {
    this.unmountStack.forEach((callback) => {
      callback?.();
    });
  }

  public isInWhitelist(url: string) {
    const locationPath = formatUrl(url);
    if (locationPath.includes('202403081409452236763')) {
      return false;
    }
    for (let i = 0; i < this.whitelist.length; i++) {
      const item = this.whitelist[i];
      if (typeof item === 'string') {
        if (item === locationPath) {
          return true;
        }
      } else if (item.test(locationPath)) {
        return true;
      }
    }
    return false;
  }

  urlIntercept(nextUrl: string, curUrl: string) {
    let ret = nextUrl;
    try {
      const curUrlIsolateCode = new QueryString().parseUrl(curUrl).getQuery(this.isolateCode);
      const nextParsedUrl = new QueryString().parseUrl(nextUrl);
      const nextUrlIsolateCode = nextParsedUrl.getQuery(this.isolateCode);
      const isolateCode = nextUrlIsolateCode || curUrlIsolateCode;
      const urlIsInWhitelist = this.isInWhitelist(nextUrl);
      if (urlIsInWhitelist) {
        if (isolateCode) {
          log.debugInfo('路由拦截');
          log.debugInfo('原始路由', nextUrl);
          log.debugInfo('isolateCode 将自动添加到路由中', isolateCode);
          nextParsedUrl.addQuery({ [this.isolateCode]: isolateCode });
          ret = nextParsedUrl.getUrl();
          log.debugInfo('路由拦截结果', ret);
        } else {
          const appInvalidEvent = new CustomEvent('appInvalidEvent', {
            detail: { curUrl, nextUrl },
            bubbles: true, // 是否冒泡，可选
            cancelable: true, // 是否可以取消，可选
          });
          document.dispatchEvent(appInvalidEvent);
          log.error('路由中不存在 appCode，将要弹出应用选择弹窗', nextUrl);
          // alert('不存在 appCode');
          // return false;
        }
      }
      return ret;
    } catch (e) {
      log.debugWarn('url 代理失败', e);
    }
    return nextUrl;
  }

  public init() {
    if (this.inited) {
      return;
    }
    // 主动触发一次
    this.urlIntercept(location.href, location.href);
    this.inited = true;
    const originPushState = window.history['pushState'];
    const originReplaceState = window.history['replaceState'];
    this.unmountStack.push(() => {
      window.history['pushState'] = originPushState;
      window.history['replaceState'] = originReplaceState;
    });

    this.unmountStack.push(() => {
      const index = window.__appIsolateInstance.indexOf(this);
      window.__appIsolateInstance.splice(index, 1);
    });
    window.history['pushState'] = createHistoryIsolateEvent(
      'pushState',
      this.urlIntercept.bind(this),
    );
    window.history['replaceState'] = createHistoryIsolateEvent(
      'replaceState',
      this.urlIntercept.bind(this),
    );
  }
}

declare global {
  interface Window {
    __appIsolateInstance: any[];
  }
}
