import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import { buiService } from '@bui/req';
import useQiankun from '@/hooks/useQiankun';
import { useSystemByKey } from '@/hooks/useSystemConfig';

export const WaterMark = () => {
  const { qiankun } = useQiankun();
  if (qiankun?.setGlobalState) {
    return null;
  }
  return <WaterMarkInner />;
};

export const WaterMarkInner = () => {
  const [showWaterMark] = useSystemByKey('watermarkFlag');

  const [userInfo, setUserInfo] = useState<any>(null);
  useEffect(() => {
    let unmount = false;
    if (showWaterMark) {
      buiService.userInfo().then((res) => {
        const user = res.user;
        if (!user) {
          return;
        }
        if (!unmount) {
          setUserInfo(user);
        }
      });
    }
    return () => {
      unmount = true;
    };
  }, [showWaterMark]);
  if (!showWaterMark || !userInfo) {
    return null;
  }
  const mark = `${userInfo?.userName}（${userInfo?.userId}）`;
  const renderMarks = () => {
    const data = Array(80).fill(0);
    return data.map((item, index) => {
      return <li key={index}>{mark}</li>;
    });
  };
  return (
    <div className={styles['water-mark']}>
      <ul>{renderMarks()}</ul>
    </div>
  );
};
